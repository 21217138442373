import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import Store from "store";

import "./scss/style.scss";

const MainLayout = React.lazy(() => import("./layout/MainLayout"));
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              path="/"
              name="home"
              render={(props) =>
                (Store.get("accessToken") !== undefined &&
                  props.history.location.pathname !== "/login") ||
                props.history.location.pathname === "/login" ? (
                  <MainLayout {...props} />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
