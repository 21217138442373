import { createStore } from "redux";

const initialState = {
  is_login: false,
  alerts: {},
  info: {},
  permission: {},
  permission_1: false,
  permission_2: false,
  permission_3: false,
  permission_4: false,
  permission_5: false,
  permission_6: false,
  permission_7: false,
  permission_8: false,
  permission_9 : false,
  permission_10 : false
};

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case "set":
      return { ...state, ...action.payload };
    case "logout":
      return { undefined, action };
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
